<template>
  <section
    :class="['section-container', classes]"
    :data-section="sectionId"
    :style="cssVars"
  >
    <component-renderer
      v-for="field in getFields"
      v-bind:key="field.id"
      :class="`col-lg-${getColumns(field)} component-wrapper`"
      v-bind:sectionId="sectionId"
      :field="field"
      v-on:component-updated="updated"
    />
  </section>
</template>

<script>
import componentRenderer from "@/components/component-renderer.vue";

export default {
  data () {
    return {
      isSuccess: false
    }
  },
  components: {
    componentRenderer
  },
  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object,
      required: false,
      default: () => {
        return {
          columns: 12,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0
        };
      }
    },
    noRow: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    }
  },
  computed: {
    getFields () {
      const { isSuccess } = this;
      return this.fields.filter((field) => {
        if (field.properties && field.properties.onSuccess) {
          return field.properties.onSuccess === 'show' ? isSuccess :
            field.properties.onSuccess === 'hide' ? !isSuccess :
              true;
        } else {
          return true;
        }
      })
    }
  },
  methods: {
    getColumns (field) {
      return field.properties && field.properties.grid
        ? field.properties.grid.columns
        : 12;
    },
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });
      // field.properties.text = properties.text;
      // updatedField.properties.text= "Hello"
      updatedField.properties = { ...updatedField.properties, ...properties };

      // console.log(
      //   "UPDATED:::",
      //   field,
      //   "properties",
      //   properties,
      //   "updatedField",
      //   updatedField
      // );
    }
  }
};
</script>

<style scoped lang="scss">

.container {
  margin-left: auto !important;
  margin-right: auto !important;

  @media (min-width: 576px) {
    max-width: 600px !important;
  }

  @media (min-width: 768px) {
    max-width: 991px !important;
  }
}

.home-page {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
