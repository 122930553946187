<template>
  <div class="m-0 p-0">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name"
    >
      <div
        class="form-group"
      >
        <label
          v-if="labelOnTop"
          :for="id"
          :style="labelOnTopStyle"
          class="input-label"
        >
          {{ $t(label) }}
          <span v-if="validation.includes('required')">*</span>
        </label>
        <!-- <div
          class="input-group-prepend"
          v-if="name == 'iban'"
        >
          <span
            class="input-group-text"
            id="basic-addon1"
          >{{ iban_prepend }}</span>
        </div> -->
        <input
          :style="styles"
          :type="inputType"
          class="form-control"
          :class="{'is-invalid': errors.length}"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ $t(label) }}
        </label>
        <div
          class="invalid-feedback"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="form-group">
        <label
          v-if="labelOnTop"
          :style="labelOnTopStyle"
          class="input-label"
        >
          {{ label }}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>(optional)</span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: "TextInput",
  data () {
    return {
      passIcon: 'regular/eye'
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp:Boolean,
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    isUpperCase: Boolean,
    toolTip: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    updateValue (val) {
      let value = this.isUpperCase ? val.toUpperCase() : val;
      if (this.name === "iban") {
        value = value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase()
      }
      this.$emit("component-updated", {
        text: value,
        slideUp:!!value
      });
      this.$emit("input", value);
      this.$store.dispatch("setComponentStatus", {
        component: this.id,
        status: !!value
      });
    }
  },
  computed: {
    iban_prepend () {
      if (this.locale == 'cs') return 'CZ'
      else if (this.locale == 'sk') return 'SK'
      else return 'PL'
    }
  }
};
</script>

<style lang="scss">
.input-group-text {
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
  
</style>
