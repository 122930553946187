<template>
  <div
    class="wrapper_file_upload"
    :style="[styles, cssVars]"
    :class="{ highlight: highlight }"
    @dragover.prevent
    @drop.stop.prevent="handleDrop"
    @dragenter.stop.prevent="onDragEnter"
    @dragleave.stop.prevent="onDragLeave"
  >
    <div>
      <label
        v-show="!selectedFile"
        class="file_upload"
        tabindex="0"
        for="file"
      >
        <input
          @change="onFilePicked"
          accept="image/*"
          class="inputfile"
          type="file"
          name="file"
          ref="file"
          id="file"
        />
        <svg
          class="upload-icon"
          width="70"
          height="48"
          viewBox="0 0 70 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M58.726 20.9659C57.2329 9.20575 47.164 0.083252 35.0006 0.083252C25.5843 0.083252 17.4048 5.58409 13.6704 14.259C6.3314 16.4559 0.833984 23.3849 0.833984 30.8333C0.833984 39.9113 7.9714 47.2845 16.9197 47.8175V47.9166H55.4665V47.9063L55.5006 47.9166C63.0378 47.9166 69.1673 41.7871 69.1673 34.2499C69.1633 31.1869 68.1324 28.2137 66.2396 25.8056C64.3468 23.3975 61.7013 21.6935 58.726 20.9659ZM21.334 23.9999H31.584V13.7499H38.4173V23.9999H48.6673L35.0006 41.0833L21.334 23.9999Z"
            fill="#A0A3BD"
          />
        </svg>
        <div
          class="text1"
          v-html="$t(this.texts.text1)"
        ></div>
        <div
          class="text2"
          v-html="$t(this.texts.text2)"
        ></div>
      </label>
      <div
        v-show="selectedFile"
        class="row row-dop justify-content-center mt-4"
      >
        <label class="wrapper_square_img">
          <img
            class="square-img img-fluid"
            ref="preview"
            src="../../assets/icons/placeholder.svg"
            alt
          />
          <div
            @click="removeImage"
            class="delete_block"
          >
            <img
              class="delete_img"
              src="../../assets/icons/delete.svg"
              alt="delete"
            />
          </div>
        </label>
      </div>
      <button
        @click.prevent="selectAnother"
        class="btn btn-upload"
      >
        {{ $t(this.texts.choose) }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductUpload",
  props: {
    fieldId: String,
    endpoint: String,
    successAction: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      highlight: false
    };
  },
  methods: {
    ...mapActions(['sendRequest']),
    clear () {
      this.selectedFile = null;
      this.setValue(null);
    },
    removeImage () {
      this.selectedFile = null;
      this.setValue(null);
      this.$refs.file.value = null;
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files)
    },
    onDragEnter () {
      this.highlight = true
    },
    onDragLeave () {
      this.highlight = false
    },
    handleDrop (e) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 10; // 10MB

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.$t(this.texts.fileSizeLimit))
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.$t(this.texts.wrongFormat))
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        this.selectedFile = files[0];
        this.setPreview(this.selectedFile);
        this.setValue(this.selectedFile);
      }
    },
    setValue (value) {
      this.$emit("component-updated", {
        value
      });
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!value
      });
    },
    setPreview (file) {
      const reader = new FileReader();

      this.selectedFile = file;
      reader.onload = e => {
        this.$refs.preview.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    sendProductPhoto (params) {
      const formData = {
        image: this.selectedFile,
        endpoint: this.endpoint,
        receiptToken: this.receiptToken,
        customerToken: this.customerToken,
        token: this.jwtToken
      }

      if (this.reUpload) {
        formData["receiptToken"] = this.receiptToken;
        formData["customerToken"] = 1;
      }

      this.$store.dispatch("uploadProductPhotos", formData).then(r => {
        // console.log(this.successAction, this.response);
        if (r.response && this.successAction) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }).catch(err => {
        console.log("Error", err)
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    selectAnother () {
      this.$refs.file.click();
    }
  },
  mounted () {
    if (this.value) {
      this.setPreview(this.value);
    }
    this.$eventHub.$on("sendProductPhotos-event", this.sendProductPhoto);
  },
  beforeDestroy () {
    this.$eventHub.$off("sendProductPhotos-event", this.sendProductPhoto);
  },
  computed: {
    ...mapGetters(["receiptToken", "campaignId", "customerToken", "jwtToken"]),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/mixins/style/_color";
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.delete_block {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: transform .5s;
  }
}
.delete_block .delete_img {
  width: 16px;
}
.inputfile {
  display: none;
}
.wrapper_file_upload {
  position: relative;
  border-radius: 8px;
  border: 17px solid #FFF;
  box-shadow: 0px 0px 16px -2px rgba(22, 22, 22, 0.65);
  padding: 0;

  > div {
    overflow: hidden;
    min-height: 450px;
    border: 2px dashed $color-3;
    background: #FFF;
    width: 100%;
    padding: 4vw 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  @media (max-width: 991px) {
    max-width: 300px;
  }
  .file_upload {
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--color-1);
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        color: var(--color-4);
        opacity: 1;
      }
    }
  }
}
.file_upload input[type="file"] {
  display: none;
}
.btn {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  z-index: 1000;
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px 30px;
  }
  &.btn-upload {
    position: relative;
    border: 2px solid #A3C6E3;
    background: #FFF;
    border-radius: 0;
    padding: 10px 35px;
    margin-top: 60px;
    color: #00388B;
  }
}
.upload-icon {
  margin-bottom: 20px;
}
</style>
