<template>
  <div class="container" ref="questionsRef" :style="cssVars">
    <div class="row flex-column justify-content-center align-items-center">
      <div class="col-lg-12 question-wrapper" v-for="(question, idx) in questions" :key="idx">
        <div v-if="question.type == 'checkbox' && question.condition == undefined">
          <div class="question" v-html="question.text"></div>
          <div class="subQuestion" v-html="question.subText"></div>
          <div class="inputWrapper" role="group">
            <div
              class="radio-input"
              v-for="(option, i) in question.options"
              :key="option.name"
              :class="questionModel[idx].indexOf(option) != -1 ? 'active' : ''"
            >
            <label class="box-container w-100" :for="`checkbox-group-${idx}-${i}`">
              <input
                v-model="questionModel[idx]"
                type="checkbox"
                :id="`checkbox-group-${idx}-${i}`"
                :name="`checkbox-group-${idx}`"
                :value="option"
                autocomplete="off"
                checked
                @change="updateCheckbox(idx)"
              >
              <span class="checkmark"></span>
              {{option}}
            </label>
            </div>
          </div>
          <input v-if="question.otherOption" class="input" :class="otherModelDisabled[idx] ? 'hide' : 'show'" :disabled="otherModelDisabled[idx]" :name="`checkbox-group-${idx}`" type="text" :placeholder="question.otherText" aria-label="Other option" value="" v-model="otherModel[idx]" @change="otherModelSelected(idx)">
        </div>
        <div v-else-if="question.type=='radio'">
          <div class="question" v-html="question.text"></div>
          <div class="subQuestion" v-html="question.subText"></div>
          <div
            class="radio-input"
            v-for="option in question.options"
            :key="option.name"
            :class="questionModel[idx] == option ? 'active' : ''"
          >
          <label class="box-container w-100" :for="option.name">
            <input
              v-model="questionModel[idx]"
              type="radio"
              :id="`radio-group-${idx}`"
              :name="`radio-group-${idx}`"
              :value="option"
              :disabled="option.disabled"
              autocomplete="off"
              checked
            >
            <span class="checkmark"></span>
            <span class="text">{{option}}</span>
          </label>
          </div>
        </div>
        <div v-else-if="question.type=='textarea'">
          <div class="question" v-html="question.text"></div>
          <textarea type="text" class="w-100" v-model="questionModel[idx]" value="" :placeholder="question.placeholder"></textarea>
        </div>
        <div v-else-if="question.type=='star'">
          <div class="question" v-html="question.text"></div>
          <div class="fiveStarWrapper pb-4">
            <div class="fiveStar">
                <div
                  v-for="(starItem , i) in stars"
                  :key="i"
                  class="starWrapper"
                  @click="onClickStars"
                >
                  <img
                    :reference="i"
                    :src="!starItem.selected ? '/assets/images/star_white.png' : '/assets/images/star_orange.png'"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.error" class="my-5">
        {{error}}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "survey",
  components: {
  },
  props: {
    mandatory: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => []
    },
    successAction: {
      type: Object,
      default: () => null
    },
    responseData: {
      type: Array,
      default: () => []
    },
    colorSchema: {
      type: String,
      default: ""
    },
    endpoint: {
      type: String
    }
  },
  data () {
    return {
      questionModel: [],
      otherModel: [],
      otherModelDisabled: [],
      error: '',
      stars: [
        { "selected" : false },
        { "selected" : false },
        { "selected" : false },
        { "selected" : false },
        { "selected" : false }
      ]
    }
  },
  created () {
    this.$eventHub.$on("sendSurvey-event", this.sendSurvey);
    for (let i = 0; i < this.questions.length; i++) {
      this.questionModel[i] = [];
      this.otherModelDisabled[i] = true;
    }
  },
  beforeDestroy () {
    this.$eventHub.$off("sendSurvey-event");
  },
  computed: {
    ...mapGetters(['customerToken' , 'campaignId', 'receiptToken']),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    ...mapActions(['sendSurveyData']),
    onClickStars (e) {
      this.stars.forEach((item , i) => {
        if (i <= e.target.attributes.reference.nodeValue) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      })
    },
    updateCheckbox (idx) {
      if (this.questions[idx].otherOption) {
        if (this.questionModel[idx].indexOf('Other') != -1) {
          this.otherModelDisabled[idx] = false
        } else {
          let i = this.questionModel[idx].indexOf(this.otherModel[idx])
          if (i != -1) {
            this.questionModel[idx].splice(i, 1)
          }
          this.otherModel[idx] = ""
          this.otherModelDisabled[idx] = true
        }
      }
    },
    otherModelSelected (idx) {
      this.questionModel[idx].push(this.otherModel[idx]);
    },
    sendSurvey (skipSurvey) {
      if (this.mandatory) {
        if (this.questionModel.some(x => x.length == 0)) {
          this.error = 'Survey is mandatory'
          return;
        }
      }
      let data = {};
      data.questions = []
      if (!skipSurvey) {
        data.questions = this.questionModel;
      }
      // data.questions[2] = 0
      // this.stars.forEach((item , i) => {
      //   if (item.selected) data.questions[2] = data.questions[2] + 1
      // })
      // Send data to backend
      data.customerToken = this.customerToken;
      data.receiptToken = this.receiptToken;
      data.campaignId = this.campaignId;
      this.sendSurveyData( { formData: data, endpoint: this.endpoint }).then(res => {
        if (this.responseData.length) {
          this.responseData.forEach(x => {
            this.$store.commit('setField', { field: x.variable, value: res[x.token] })
          })
        }
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        this[fn](params, this);
      }).catch(err => {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      })
    }
  }
}
</script>
<style type="text/css" scoped>
.tg  {border-collapse:collapse;border-color:#C44D58;border-spacing:0;}
.tg td{background-color:#F9CDAD;border-color:#C44D58;border-style:solid;border-width:0px;color:#002b36;
  font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{background-color:#FE4365;border-color:#C44D58;border-style:solid;border-width:0px;color:#fdf6e3;
  font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-zv4m{border-color:#ffffff;text-align:left;vertical-align:top}
.tg .tg-b6o2{background-color:#FFA4A0;border-color:#ffffff;text-align:left;vertical-align:top}
</style>
<style lang="scss" scoped>

@media (max-width: 600px) {
  .tg td {
    font-size: 14px;
    padding: 1px;
  }
  .tg th {
    font-size: 14px;
    padding: 1px;
  }
}

@media (max-width: 450px) {
  table {
    position: relative;
    left: -1rem;
  }
  .tg td {
    font-size: 12px;
    padding: 1px;
  }
  .tg th {
    font-size: 12px;
    padding: 1px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.question-wrapper {
  width: 100%;
  color: var(--text-color-2);
  @media (max-width: 991px) {
    width: 90%;
  }
}

.question {
	display: block;
	font-size: 1.2em;
	margin-top: 30px;
  margin-bottom: 20px;
	font-weight: bold;
}

.subQuestion {
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  margin-bottom: 20px;
}

.surveyBtnWrapper {
  display: flex;
  justify-content: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    width: 100%;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    margin-bottom: 5px;
  }
}

.active {
  .box-container {
    opacity: 1;
  }
  .text {
    font-weight: 800;
  }
}
.hide {
  display: none;
}

.show {
  display: block;
}

.input {
	background: #FFFFFF;
	border: 1px solid #D1E7FF !important;
	box-sizing: border-box;
	border-radius: 8px;
	transition: border-color 0.15s;
	height: 40px;
  width: 100%;
}
.fiveStar {
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  img {
    max-width: 36px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}
.fiveStarWrapper {
  display: flex;
  justify-content: flex-start;

  cursor: pointer;
}

.starWrapper {
  display: flex;
  max-width: 40px;
}

/* The container */
.box-container {
  display: block;
  position: relative;
  padding: 5px 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .3;
}

/* Hide the browser's default radio button */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 15%;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid gray;
  border-radius: 50%;
  margin-bottom: 0 !important;
}

/* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: #FFF;
  border: 2px solid var(--color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.box-container .checkmark:after {
  top: 1px;
	left: 1px;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: var(--color);
}

</style>
