<template>
  <div class="wrapper_file_upload">
    <label
      class="file_upload"
      :class="{'dragging': dragging}"
      @mouseover="uploadSectionHover = true"
      @mouseleave="uploadSectionHover = false"
      @drop.prevent="drop"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      :disabled="disabled"
    >
      <div class="file_upload_inner">
        <input
          @click="onClick"
          @change="onFilePicked($event)"
          accept="image/*, application/pdf"
          class="inputfile"
          type="file"
          name="file"
          ref="file"
          tabindex="-1"
        />
        <div class="drag-drop position-relative w-100 h-100 ">
          <div>
            <p class="descriptionStyle" v-html="description"/>
            <p class="subDescriptionStyle" v-html="subDescription"/>
          </div>
          <button @click="onClick" class="btn dm-btn-2"><span>{{btnText}}</span></button>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "file-uploader",
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      dragging: false
    }
  },
  props: {
    fileUploaderBorderColor: {
      required: false,
      type: String,
      default: "#969696"
    },
    fileUploaderBackgroundColor: {
      required: false,
      type: String,
      default: "transparent"
    },
    description: {
      type: String,
      default: ''
    },
    subDescription: {
      type: String,
      default: ''
    },
    descriptionStyle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    btnText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(["uploadFile"]),
    onFilePicked (event) {
      console.log('>>>>> on file picked', event);
      const files = event.target.files || event.dataTransfer.files;
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb
      if (files && files[0]) {
        let filename = files[0].name;
        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.texts.fileSizeLimit);
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg", "tiff", "pdf"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          console.log('found length >>>>>', this.texts.wrongFormat);
          this.showModal(this.texts.wrongFormat);
          return;
        }
        this.uploadFile(files[0]);
        this.$emit('file-picked', files[0]);
        this.$refs.file.value = "";
      }
    },
    dragover () {
      this.dragging = true;
    },
    dragleave () {
      this.dragging = false;
    },
    drop (e) {
      this.dragging = false;
      this.onFilePicked(e);
    },
    onClick () {
      this.$refs.file.value = "";
      this.$refs.file.click();
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-drop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &_btn {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      font-weight: 600;
        font-size: 18px;
        color: var(--color1);
        border: 1px solid var(--color1);
        border-radius: 40px;
        outline: none;
        background-color: #FFFFFF;
    }
}
.wrapper_file_upload {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.file_upload {
    cursor: pointer;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    margin: 0;
    height: 100%;
    &_inner {
        width: 100%;
        height: 100%;
        padding: 50px 22px;
        display: flex;
    }
}
.file_upload input[type="file"] {
    display: none;
}
.hidden {
    display: none;
}
.inputfile {
    display: none;
}
</style>
