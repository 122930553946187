export let myHistory = [];
export default {
  methods: {
    goToPage: (pageName, self) => {
      self.$eventHub.$emit('goToPage-event', pageName);
      // change url when moving to another page
      if (location.pathname.split('/').join('') !== pageName) {
        history.replaceState(myHistory, null, '/' + pageName)
      }
    },
    sendProductPhotos:(params,buttonComponent)=>{
      let fieldId = params;
      // console.log("1. will SEND products:::", fieldId, buttonComponent)

      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });

      buttonComponent.$eventHub.$emit('sendProductPhotos-event', fieldId);
    },
    sendForm: (params, buttonComponent) => {
      if (params.indexOf(',') > -1) {
        params = params.split(',')
      }
      let formId = params;
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });

      buttonComponent.$eventHub.$emit('getFormData-event', formId);
    },
    sendSms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendSms-event');
    },
    verifySms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('verifySms-event');
    },
    sendProducts: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendProducts-event', formId);
    },
    sendBarcodeProducts: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendBarcodeProducts-event');
    },
    sendSurvey: (formId, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendSurvey-event', formId);
    },
    doneTrigger: (component, eventResult) => {
      component.$eventHub.$off('done-event-trigger');
      component.loading = false
      // console.log('1. will Done Trigger', eventResult)
      // console.log('event Result',eventResult)
      if (eventResult.status) {
        eventResult.parent.clear()
      }
      if (!eventResult.status && eventResult.message) {
        // console.log("EVENT RESULT>>>", eventResult)
        component.showModal(eventResult.message)
      }

    },
    sendReceipt: (component, buttonComponent)=>{
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendReceipt-event',buttonComponent)
    },
    isMobileOrTablet ( currentScreenSize ) {
      return currentScreenSize < 992 ? true : false
    },
    displayModal (params, component) {
      component.showModal(params.message, params.type)
    }
  }
}
