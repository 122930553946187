<template>
  <component
    :is="field.fieldType"
    v-bind="field.properties"
    :data-field-id="field.fieldId"
    :field-id="field.fieldId"
    :data-field-type="field.fieldType"
    @component-updated="updated"
    :style="`${fieldCssVars(field)}`"
  />
</template>

<script>
export default {
  props: {
    field: {
      type: [Array, Object],
      required: true
    },
    sectionId: {
      type: [Number, String],
      required: false
    },
    isFromBuilder: {
      type: Boolean,
      required: false
    }
  },
  mounted () {
    /* global $ */
    // $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    fieldCssVars (field) {
      if (field.properties && field.properties.grid) {
        const fieldStyles = field.properties.grid;
        return `
              margin-top:${fieldStyles.marginTop};
              margin-bottom:${fieldStyles.marginBottom};
              margin-left:${fieldStyles.marginLeft};
              margin-right:${fieldStyles.marginRight};
              padding-top:${fieldStyles.paddingTop};
              padding-left:${fieldStyles.paddingLeft};
              padding-right:${fieldStyles.paddingRight};
              padding-bottom:${fieldStyles.paddingBottom};
      `;
      }
    },
    updated (properties) {
      this.$emit("component-updated", this.field, properties);
    }
  }
};
</script>
