<template>
  <div class="wrapper">
    <div class="dates">
      Aktionszeitraum
      <br>
      <span v-html="dates"></span>
    </div>
    <div class="texts">
      <div class="text1">
        <span v-html="text1"></span>
      </div>
      <div class="divider-desktop"></div>
      <div class="divider-mobile"></div>
      <div class="text2">
        <span v-html="text2"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CampaignDisclaimer",
  props: {
    dates: {
      type: String,
      default: "01.01.2021 - 31.12.2021"
    },
    text1: {
      type: String,
      default: "TEXT 2"
    },
    text2: {
      type: String,
      default: "TEXT 2"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_color";
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 20px;
  background: #FFF;
  border-radius: 12px;
  border: 2px solid $color-3;
  background: #FFF;

  .dates {
    border-radius: 10px;
    background: $color-3;
    padding: 20px;
    color: #FCFCFC;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .texts {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding: 5px;
  }

  .divider-desktop {
    height: 100px;
    background: $color-3;
    border: 4px solid $color-3;
    border-radius: 4px;
  }

  .divider-mobile {
    display: none;
  }

  .text1, .text2 {
    color: $color-4;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
    flex-direction: column;
    row-gap: 20px;

    .dates {
      width: 100%;
    }

    .texts {
      flex-direction: column;
      row-gap: 20px;
    }

    .divider-desktop {
      display: none;
    }
    .divider-mobile {
      display: block;
      width: 100%;
      height: 4px;
      background: $color-3;
      border: 4px solid $color-3;
      border-radius: 4px;
    }
  }
}

</style>
