<template>
  <div class="steps_container mobileStepContainer" :style="[styles, cssVars]">
    <ul class="steps_ul progressbar">
      <li
        v-for="(step, idx) in steps"
        :key="idx"
        class="step_action"
        :data-step="idx + 1"
        :class="activeIndex > idx ? 'active' : ''"
      >
        <div class="bg">
          <span v-if="activeIndex > idx" class="doneContainer"></span>
          <div v-else class="stepNumber">{{idx + 1}}</div>
        </div>
        <div class="step_text" v-html="step.title">
        </div>
        <div class="inner_step_action"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "step-counter",
  props: {
    grid: {
      type: Object,
      default: () => {
        `backgroundColor: "#fff";
        
        `;
      }
    },
    steps: {
      type: Array,
      required: true
    },
    actions: {
      type: Object
    },
    colorSchema: {
      required: false,
      type: String,
      default: '#000'
    },
    styles: {
      type: Object,
      default: () => {}
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      currentstep: {
        page: "bankInformation"
      }
    };
  },
  computed: {
    active () {
      const idx = this.steps.findIndex(
        step => {
          return step.page === this.$store.getters.currentPage()
        }
      );
      return idx;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mobileStepContainer{
  @media(max-width: 1024px){
    max-width: 50%   !important;
  }
  @media(max-width: 450px){
    max-width: 100% !important;
  }
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
  li:not(:last-child) {
    .bg::after {
      content: ' ';
      width: 18px;
      height: 16px;
      max-width: 100%;
      // background-image: url(@/assets/images/steps/arrow_new.svg) !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      margin-left: 15%;
      @media(max-width: 1024px){
          width: 70%;
          height: 30px;
          margin-left: 55%;
      }
      @media (max-width: 768px) {
          width: 80%;
          height: 30px;
          margin-left: 50%;
          background-image: none;
      }
      @media (max-width: 450px){
          width: 60%;
          height: 30px;
          margin-left: 67%;
          background-image: none;
      }
    }
  }
  li:not(:last-child).active {
    .bg::after {
      content: ' ';
      width: 18px;
      height: 16px;
      max-width: 100%;
      // background-image: url(@/assets/images/steps/arrow_done.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      margin-left: 15%;
      @media(max-width: 1024px){
          width: 70%;
          height: 30px;
          margin-left: 55%;
      }
      @media (max-width: 768px) {
          width: 80%;
          height: 30px;
          margin-left: 50%;
          background-image: none;
      }
      @media (max-width: 450px){
          width: 60%;
          height: 30px;
          margin-left: 67%;
          background-image: none;
      }
    }
    .bg>.doneContainer>.done{
      @media(max-width: 450px){
        margin-top: 8px;
      }
    }
  }
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 32%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
}

.steps {
  padding-top: 160px;
  padding-bottom: 110px;
}

.steps_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.step_action {
  .bg {
    font-size: 2vw;
    line-height: 3vw;
    width: 4vw;
    height: 4vw;
    border: 2px solid inherit;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0px auto 0px auto;
    background-color: var(--color);
    z-index: 2;
    position: relative;
    @media(max-width: 450px){
      font-size: 7vw;
      width: 10vw;
      height: 10vw;
    }
    // opacity: 0.2;
    .stepNumber {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
      color: #FFF;
    }
    .done {
      max-width: 2vw;
      @media(max-width: 450px){
        max-width: 5vw;
      }
    }
  }
  .step_text {
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
    text-align: center !important;
  }
  &.active {
    .bg {
      background: linear-gradient(132.74deg, var(--color-6) -15.69%, var(--color-5) 114.64%),linear-gradient(0deg, #EBF3F8, #EBF3F8);
    }
    .step_text {
      color: var(--color-5);
      font-weight: 500;
    }
  }
}

@media (max-width: 400px) {
   .steps_container {
     padding: 0 !important;
   }
   .step_text {
     font-size: 14px !important;
   }
}
</style>
