<template>
  <div class="stepCardWrapper">
    <div class="stepImage">
      <img :src="require(`@/${imageSource}`)" alt="">
    </div>
    <div class="stepCardContent">
      <p class="stepTitle">{{ stepTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-card",
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: true,
      type: String
    },
    stepTitle: {
      required: true,
      type: String,
      default: "Step Title"
    }
  }
};
</script>

<style lang="scss" scoped>
.stepCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  height: 220px;
  max-width: 300px;

  .stepImage img {
    height: 150px;
    width: 150px;
  }

  .stepTitle {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: #4F4F4F;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

</style>
