<template>
  <button
    type="button"
    class="btn"
    :style="`background-color: ${backgroundColor}; color: ${textColor}; border-color: ${borderColor}; border-radius: ${borderRadius}`"
    :disabled="disabled || loading"
  >
    <div v-if="!loading">
      <slot></slot>
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      :style="`color: ${textColor};`"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      backgroundColor: "#424242",
      textColor: "#fff",
      borderColor: "",
      borderRadius: ""
    };
  }
};
</script>
<style>
</style>
