<template>
  <div class="terms">
    <div class="terms-card" v-for="campaign in campaigns" :key="campaign.id">
      <img
        class="terms-card-banner"
        :src="require(`@/${campaign.banner}`)"
        :alt="campaign.alt"
      />
      <p class="campaign-name">{{ campaign.name }}</p>
      <div class="terms-card-links">
        <a class="terms-link" :href="campaign.terms" target="_blank"
          >Teilnahmebedingungen</a
        >
        <a class="terms-link" :href="campaign.faq" target="_blank">FAQ</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms-component",
  data() {
    return {
      campaigns: [
        {
          id: 1,
          banner: "assets/images/banner/banner-desktop-old.jpg",
          name: "Ariel Kleingebinde Aktion",
          alt: "Ariel Kleingebinde Aktion",
          terms:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/terms.pdf",
          faq: "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/faq.pdf",
        },
        {
          id: 2,
          banner: "assets/images/banner/banner-desktop.jpg",
          name: "Ariel Touch of Unstoppables Aktion",
          alt: "Ariel Touch of Unstoppables Aktion",
          terms:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-cb-dach/terms.pdf",
          faq: "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-cb-dach/faq.pdf",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.terms {
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 50px;

  @media (max-width: 1010px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: auto;
    row-gap: 40px;
  }

  &-card {
    display: flex;
    border-radius: 48px;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    row-gap: 20px;
    margin: 0px auto;

    .campaign-name {
      color: white;
      margin: 0 !important;
    }
    &-banner {
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 48px 48px 0px 0px;

      @media (max-width: 1280px) {
        height: 160px;
      }

      @media (max-width: 600px) {
        height: 120px;
      }
    }

    &-links {
      display: flex;
      flex-flow: column;
      row-gap: 20px;
      width: 100%;
      padding-bottom: 20px;
      .terms-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        background-color: white;
        border-radius: 25px;
        color: black;
      }
    }
  }
}
</style>
