<template>
  <div
    class="wrapper"
    :class="{ grabbing: isDown }"
  >
    <canvas
      :width="width"
      :height="height"
      ref="canvas"
      class="canvas"
      @mousedown="handleMouseDown"
      @touchstart="handleMouseDown"
      @mousemove="handleMouseMove"
      @touchmove="handleMouseMove"
      @mouseup="handleMouseUpOut"
      @touchend="handleMouseUpOut"
      @mouseout="handleMouseUpOut"
    ></canvas>

    <canvas
      style="display: none"
      ref="croppedImageCanvas"
    ></canvas>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'Cropper',
  props: {
    height: Number,
    width: Number,
    imagePosition: Object,
    layer: Object,
    ratio: Number,
    points: Array,
    color: {
      type: String,
      default: '#3E3D40'
    },
    background: String
  },
  data () {
    return {
      isDown: false,
      startX: 0,
      startY: 0,
      nearest: 0,
      dots: [],
      ctx: null,
      initialPoints: [],
      freezePosition: false
    }
  },

  watch: {
    imagePosition (imagePosition) {
      if (imagePosition) {
        if (this.dots.length) {
          this.initialPoints = this.getDots()
        } else {
          this.initialPoints = this.points
        }
        this.drawPoints()
      } else {
        if (this.ctx) {
          this.ctx.clearRect(0, 0, this.width, this.height)
        }
        if (this.dots) {
          this.dots = []
        }
        this.$emit('input', null)
      }
    },

    layer (layer) {
      if (layer) {
        this.$emit('input', {
          ctx: this.getImageFromSelectedArea()
        })
      }
    }
  },

  mounted () {
    this.ctx = this.$refs.canvas.getContext('2d');
    this.$eventHub.$on('freezePosition', this.freezeDots)
  },

  methods: {
    freezeDots (val) {
      this.freezePosition = val;
    },
    drawPoints () {
      if (!this.freezePosition) {
        if (this.initialPoints && this.initialPoints.length) {
          this.dots = []
          this.dots.push({
            x: this.imagePosition.x0 + this.initialPoints[0] * this.ratio,
            y: this.imagePosition.y0 + this.initialPoints[1] * this.ratio
          })
          this.dots.push({
            x: this.imagePosition.x0 + this.initialPoints[2] * this.ratio,
            y: this.imagePosition.y0 + this.initialPoints[3] * this.ratio
          })
          this.dots.push({
            x: this.imagePosition.x0 + this.initialPoints[4] * this.ratio,
            y: this.imagePosition.y0 + this.initialPoints[5] * this.ratio
          })
          this.dots.push({
            x: this.imagePosition.x0 + this.initialPoints[6] * this.ratio,
            y: this.imagePosition.y0 + this.initialPoints[7] * this.ratio
          })
        } else {
          this.dots.push({ x: this.imagePosition.x0, y: this.imagePosition.y0 })
          this.dots.push({ x: this.imagePosition.x1, y: this.imagePosition.y0 })
          this.dots.push({ x: this.imagePosition.x1, y: this.imagePosition.y1 })
          this.dots.push({ x: this.imagePosition.x0, y: this.imagePosition.y1 })
        }
      } else {
        this.dots = [];
        this.dots.push({ x: this.imagePosition.x0, y: this.imagePosition.y0 })
        this.dots.push({ x: this.imagePosition.x1, y: this.imagePosition.y0 })
        this.dots.push({ x: this.imagePosition.x1, y: this.imagePosition.y1 })
        this.dots.push({ x: this.imagePosition.x0, y: this.imagePosition.y1 })
      }

      this.dots = this.dots.map(dot => this.checkDrawPosition(dot, this.imagePosition))

      this.draw()
      this.reOffset()
    },

    reOffset () {
      const BB = this.$refs.canvas.getBoundingClientRect()
      this.offsetX = BB.left
      this.offsetY = BB.top
    },

    draw () {
      this.ctx.clearRect(0, 0, this.width, this.height)

      // draw black
      const points = []
      for (let i = 0; i < this.dots.length; i++) {
        points.push({ x: this.dots[i].x, y: this.dots[i].y })
      }
      points.push({ x: this.dots[0].x, y: this.dots[0].y })

      points.push({ x: this.imagePosition.x0, y: this.imagePosition.y0 })
      points.push({ x: this.imagePosition.x0, y: this.imagePosition.y1 })
      points.push({ x: this.imagePosition.x1, y: this.imagePosition.y1 })
      points.push({ x: this.imagePosition.x1, y: this.imagePosition.y0 })
      points.push({ x: this.imagePosition.x0, y: this.imagePosition.y0 })

      this.ctx.beginPath()

      this.ctx.moveTo(this.imagePosition.x0, this.imagePosition.y0)
      for (let i = 0; i < points.length; i++) {
        this.ctx.lineTo(points[i].x, points[i].y)
      }

      this.ctx.fillStyle = this.background
      this.ctx.fill()

      for (let i = 0; i < this.dots.length; i++) {
        this.drawDot(this.dots[i], this.color)
        this.drawLine(this.dots[i], this.dots[i + 1] || this.dots[0], this.color)
      }

      this.emitLayer()
    },

    drawDot (dot, color) {
      this.ctx.beginPath()
      this.ctx.fillStyle = color
      this.ctx.fillRect(dot.x - 4, dot.y - 4, 8, 8)
    },

    drawLine (dot1, dot2, color) {
      this.ctx.beginPath()
      this.ctx.lineWidth = 2
      this.ctx.moveTo(dot1.x, dot1.y)
      this.ctx.lineTo(dot2.x, dot2.y)
      this.ctx.strokeStyle = color
      this.ctx.stroke()
      this.ctx.lineWidth = 1
    },

    getDots () {
      const points = []

      points.push(parseInt((this.dots[0].x - this.imagePosition.x0) / this.ratio))
      points.push(parseInt((this.dots[0].y - this.imagePosition.y0) / this.ratio))

      points.push(parseInt((this.dots[1].x - this.imagePosition.x0) / this.ratio))
      points.push(parseInt((this.dots[1].y - this.imagePosition.y0) / this.ratio))

      points.push(parseInt((this.dots[2].x - this.imagePosition.x0) / this.ratio))
      points.push(parseInt((this.dots[2].y - this.imagePosition.y0) / this.ratio))

      points.push(parseInt((this.dots[3].x - this.imagePosition.x0) / this.ratio))
      points.push(parseInt((this.dots[3].y - this.imagePosition.y0) / this.ratio))

      return points
    },

    handleMouseDown (e) {
      if (!this.ctx || !this.dots.length) {
        return
      }
      e.preventDefault()
      e.stopPropagation()

      this.startY = e.targetTouches ? e.targetTouches[0].pageY : e.pageY
      this.startX = e.targetTouches ? e.targetTouches[0].pageX : e.pageX

      let positionX = e.offsetX
      let positionY = e.offsetY

      if (e.touches) {
        let rect = e.target.getBoundingClientRect()
        positionX = e.touches[0].clientX - rect.x
        positionY = e.touches[0].clientY - rect.y
      }

      this.nearest = this.closestDot(this.dots, positionX, positionY)
      this.draw()

      this.isDown = true
    },

    handleMouseUpOut (e) {
      if (!this.ctx || !this.dots.length) {
        return
      }
      e.preventDefault()
      e.stopPropagation()

      this.isDown = false
      this.nearest = null

      this.draw()
    },

    handleMouseMove (e) {
      if (!this.ctx || !this.dots.length) {
        return
      }
      if (!this.isDown) return

      e.preventDefault()
      e.stopPropagation()

      this.mouseY = e.targetTouches ? e.targetTouches[0].pageY : e.pageY
      this.mouseX = e.targetTouches ? e.targetTouches[0].pageX : e.pageX

      const dx = this.mouseX - this.startX
      const dy = this.mouseY - this.startY
      const dot = this.nearest.dot

      this.startX = this.mouseX
      this.startY = this.mouseY

      dot.x += dx
      dot.y += dy

      this.checkDrawPosition(dot, this.imagePosition)

      // redraw
      this.draw()
    },

    closestDot (dots, mx, my) {
      let dist = 100000000
      let index, pt

      for (let i = 0; i < dots.length; i++) {
        const dx = mx - dots[i].x
        const dy = my - dots[i].y
        const thisDist = dx * dx + dy * dy
        if (thisDist < dist) {
          dist = thisDist
          pt = { x: dots[i].x, y: dots[i].y }
          index = i
        }
      }

      const dot = dots[index]
      return ({ pt, dot, originalLine: { x: dot.x, y: dot.y } })
    },

    checkDrawPosition (dot, pos) {
      const newDot = {}

      if (dot.x < pos.x0) newDot.x = pos.x0
      if (dot.x > pos.x1) newDot.x = pos.x1
      if (dot.y < pos.y0) newDot.y = pos.y0
      if (dot.y > pos.y1) newDot.y = pos.y1

      return Object.assign(dot, newDot)
    },

    getImageFromSelectedArea () {
      let canvas = this.$refs.canvas
      let originalImgCanvas = this.layer.ctx.canvas
      let resultCanvas = this.$refs.croppedImageCanvas

      const resultCtx = resultCanvas.getContext('2d')
      const ratioY = originalImgCanvas.height / canvas.height

      const arrayOfX = this.dots.map(d => d.x)
      const arrayOfY = this.dots.map(d => d.y)

      // get coordinates in preview canvas
      let maxX = Math.max(...arrayOfX)
      let maxY = Math.max(...arrayOfY)
      let minX = Math.min(...arrayOfX)
      let minY = Math.min(...arrayOfY)


      // update preview coordinates to fit original size image
      maxX = maxX * ratioY
      maxY = maxY * ratioY
      minX = minX * ratioY
      minY = minY * ratioY

      const imageWidth = maxX - minX
      const imageHeight = maxY - minY

      resultCtx.clearRect(0, 0, resultCanvas.width, resultCanvas.height)

      resultCanvas.width = imageWidth
      resultCanvas.height = imageHeight

      resultCtx.drawImage(
        originalImgCanvas,
        minX,
        minY,
        imageWidth,
        imageHeight,
        0,
        0,
        imageWidth,
        imageHeight
      )

      return resultCtx
    },

    emitLayer: debounce(function () {
      if (!this.layer) {
        return
      }
      this.$emit('input', {
        ctx: this.getImageFromSelectedArea()
      })
    }, 500)
  }
}
</script>

<style scoped>
  .wrapper {
    font-size: 0;
    position: absolute !important;
    top: 0;
    left: 0;
    cursor: grab;
  }
  .wrapper.grabbing {
    cursor: grabbing;
  }
</style>
