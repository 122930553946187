<template>
  <form-wizard
    ref="pageSlider"
    :hideButtons="!pageProperties.testing"
    @on-change="handlePageChange"
    :pageFooter="footer"
    :pageHeader="header"
    :backgroundColor="$store.state.backgroundColor"
  >
    <modal />
    <tab-content
      v-for="page in pageProperties.pages"
      :key="page.pageId"
      :title="page.pageName"
      v-bind="page"
    >
      <pageSection
        v-for="section in page.sections"
        :key="section.id"
        v-bind="section"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import pageSection from "@/components/section.vue";
import { FormWizard, TabContent } from "@/components/renderers/FormWizard/index.js";
import debounce from "lodash/debounce";
import modal from "./modal.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
  data: () => {
    return { footer: {}, header: {} };
  },
  components: {
    pageSection,
    FormWizard,
    TabContent,
    modal
  },
  props: {
    pageId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(["pageProperties", "getPages"]),
    pages () {
      return this.$store.getters.getPages;
    }
  },
  mounted () {
    this.footer = this.pageProperties.footer;
    this.header = this.pageProperties.header;
  },
  methods: {
    ...mapMutations(["setStep", "setPageProperties"]),
    updateProperties: debounce(function (value) {
      this.setPageProperties(JSON.parse(value));
    }, 2000),
    handlePageChange: function (prevIndex, nextIndex) {
      const pageStatus = {
        current: this.pageProperties.pages[nextIndex],
        currentIdx: nextIndex,
        prev: this.pageProperties.pages[prevIndex],
        prevIdx: prevIndex
      };
      this.setStep({ pageStatus });
      // console.log("Page Change,", nextIndex, pageStatus, "dependencies");
      this.scrollTo("body");
      // if (
      //   this.pageProperties.pages[nextIndex] &&
      //   this.pageProperties.pages[nextIndex].locale
      // ) {
      //   this.locale = this.pageProperties.pages[nextIndex].locale;
      // }
      this.footer = this.pageProperties.footer;
      this.header = this.pageProperties.header;
    }
  }
};
</script>

