import Vue from 'vue'
import VueI18n from 'vue-i18n'
import DE from './languages/de'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    de: DE
  }
});

export default i18n
