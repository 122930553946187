import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => {
    return import(`@/${pagePath}`)
  };
}


const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/ariel-old',
    name: 'ArielOldHome',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes
})

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  let path = window.location.pathname;
  let campaign;
  try {
    let campaignName = path.split('/')[1]
     if(campaignName) campaign = require(`../store/campaigns/${campaignName}`).default;
    if(campaign) store.replaceState(campaign);
    next();
  } catch (error) {
    console.log(error);
    next();
  }
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    const path = to.fullPath
    const campaignPath = window.location.pathname;
    const campaignName = campaignPath.split('/')[1]

    /**
       * Check the campaign ending date, and if its true,
       * redirect to 'campaign-is-over' page
    */
    const endDate = new Date(store.state.campaignEndDate).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    let allowedPages = ['campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'iban-reupload', 'error', 'expired']
    // if (camEnded) {
    //   if(!allowedPages.find((x) => path.includes(x)) && to.path !== `/campaign-is-over` ){
    //     next(`/campaign-is-over`)
    //   }else{
    //     next()
    //   }
    // } else {
    //   next ()
    // }
  }
})

export default router
