<template>
  <div ref="embedContainer" class="embed-container" :class="mainWrapperClass">
    <div ref="pdfHolder" class="pdf-holder">
    </div>
  </div>
</template>
<script>
export default {
  name: "docs-embed",
  props: {
    docSource: {
      type: String,
      required: false,
      default: ''
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data(){
    return{
      hasReplaced: false,
    }
  },
  mounted () {
    // console.log('docSOURCE', this.docSource)
    this.fetchDoc(this.docSource)
  },
  methods:{
    fetchDoc (val){
      let divContainer = this.$refs.embedContainer
      let pdfHolder = this.$refs.pdfHolder
      let url = val;
      if (url.startsWith("https://docs.google.com/document/d/")) {
        // create div and replace iframe
        let newDiv = document.createElement('div');
        newDiv.classList.add("embedded-doc"); // optional
        if(!this.hasReplaced){
          divContainer.replaceChild(newDiv, pdfHolder);
          this.hasReplaced = true
        } else {
          let childToReplace;
          if(divContainer?.childNodes){
            childToReplace = divContainer.childNodes[0]
          }
          console.log('HEY', childToReplace)
          divContainer.replaceChild(newDiv, childToReplace)
        }
        // CORS request
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function() {
            // display response
            let tempXHR = xhr.responseText.replaceAll('<img', '<img referrerpolicy="no-referrer" ')
            newDiv.innerHTML = tempXHR;
        };
        xhr.send();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.embed-container{
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
}
:deep(img) {
  max-width: 100vw !important;
}
:deep(.doc-content){
  max-width: 100vw !important;
}
</style>
