<template>
  <ValidationObserver ref="form">
    <form
      v-if="!loading"
      :id="fieldId"
    >
      <template v-for="field in fields">
        <componentRenderer
          :class="`col-lg-${field.properties.grid.columns}`"
          :field="field"
          style="margin-top: 20px"
          @component-updated="updated"
          :key="field.id"
        />
      </template>
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ReclaimBucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: Object,
      default: () => {
        return null
      }
    },
    endpoint: {
      type: String,
      default: "/reclaim"
    },
    fields: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters(["campaignId"])
  },
  methods: {
    ...mapActions(['sendRequest'])
  },
  mounted () {
    if (this.$route.query && this.$route.query.token) {
      const formData = {
        receiptToken: this.$route.query.token,
        campaignId: this.campaignId
      }
      this.sendRequest({ endpoint: this.endpoint, formData })
      .then(res => {
        if (res.response && !res.errorCode) {
          this.loading = false
        } else {
          this.goToPage('expired', this)
        }
      }).catch(err => {
        this.goToPage('error', this)
      })
    } else {
      this.goToPage('error', this)
    }
  }
};
</script>
