<template>
  <div class="steps" :style="[cssVars]">
    <div v-for="(step, idx) in steps" :key="idx" class="step" :class="`${idx}`" :style="styles">
      <div class="image-wrapper stepImageWrapperSize" :style="step.icon.bgStyles">
        <img class="step-image" :src="require(`@/${step.icon.src}`)" :alt="step.icon.alt" :style="step.icon.styles">
      </div>
      <div class="step-title" v-text="step.stepText"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "steps-horizontal",
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    styles: {
      type: Object,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.step-image{
  width: 100%;
  @media(max-width: 741px){
    top: 14px !important;
  }
  @media(max-width: 450px){
    top: 10px !important;
  }
}
  .steps {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    @media (max-width: 667px) {
      flex-direction: column;
      align-items: center;
      row-gap: 100px;
    }
    .step:not(:last-child) {
      .image-wrapper::after {
        content: ' ';
        width: 2.4vw;
        height: 2vw;
        max-width: 100%;
        background-image: url('@/assets/images/steps/arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        margin-left: 3vw;
        @media(max-width: 667px){
          top: 130%;
          left: 25%;
          margin-left: 3vw;
          transform: translateY(0);
          transform: rotate(90deg);
          width: 10.4vw;
          height: 12vw;
        }
        @media(max-width: 568px){
          left: 30%;
        }
        @media(max-width: 450px){
          left: 35%;
        }
        @media(max-width: 320px){
          left: 40%;
        }
      }
    }
  }
  .step {
    position: relative;
    max-width: 210px;
    .image-wrapper {
      position: relative;
    }
  }
  
.step-title {
  margin-top: 30px;
  text-align: center;
  color: var(--color);
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
  }
}
</style>
