<template>
  <div class="wrapper" :style="cssVars">
    <span class="title">{{ $t(title) }} </span>
    <div
      class="ref-wrapper"
      :style="styles"
    >
      <span
        class="form-control"
        ref="target"
        id="copied-tooltip"
      >{{ value }}</span>
      <svg @click="copy" style="cursor: pointer;" width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1302_2591)">
          <path d="M25.0427 5.27268H21.2727V3.86632C21.2727 2.98556 20.9228 2.14087 20.3001 1.51808C19.6773 0.895291 18.8326 0.54541 17.9518 0.54541L3.32091 0.54541C2.44015 0.54541 1.59546 0.895291 0.972672 1.51808C0.34988 2.14087 0 2.98556 0 3.86632L0 20.8609C0 21.7416 0.34988 22.5863 0.972672 23.2091C1.59546 23.8319 2.44015 24.1818 3.32091 24.1818H7.09091V25.5881C7.09091 26.4689 7.44079 27.3136 8.06358 27.9364C8.68637 28.5592 9.53106 28.909 10.4118 28.909H25.0427C25.9235 28.909 26.7682 28.5592 27.391 27.9364C28.0138 27.3136 28.3636 26.4689 28.3636 25.5881V8.59359C28.3636 7.71283 28.0138 6.86815 27.391 6.24535C26.7682 5.62256 25.9235 5.27268 25.0427 5.27268ZM2.36364 20.8609V3.86632C2.36364 3.61243 2.46449 3.36895 2.64401 3.18942C2.82354 3.0099 3.06702 2.90905 3.32091 2.90905H17.9518C18.2057 2.90905 18.4492 3.0099 18.6287 3.18942C18.8082 3.36895 18.9091 3.61243 18.9091 3.86632V20.8609C18.9091 21.1147 18.8082 21.3582 18.6287 21.5378C18.4492 21.7173 18.2057 21.8181 17.9518 21.8181H3.32091C3.06702 21.8181 2.82354 21.7173 2.64401 21.5378C2.46449 21.3582 2.36364 21.1147 2.36364 20.8609ZM26 25.5881C26 25.842 25.8991 26.0855 25.7196 26.265C25.5401 26.4446 25.2966 26.5454 25.0427 26.5454H10.4118C10.1579 26.5454 9.91445 26.4446 9.73492 26.265C9.5554 26.0855 9.45455 25.842 9.45455 25.5881V24.1818H17.9518C18.8326 24.1818 19.6773 23.8319 20.3001 23.2091C20.9228 22.5863 21.2727 21.7416 21.2727 20.8609V7.63632H25.0427C25.1684 7.63632 25.2929 7.66108 25.4091 7.70919C25.5252 7.75729 25.6307 7.82781 25.7196 7.9167C25.8085 8.00559 25.879 8.11112 25.9271 8.22726C25.9752 8.3434 26 8.46788 26 8.59359V25.5881Z" :fill="colorSchema"/>
        </g>
        <defs>
          <clipPath id="clip0_1302_2591">
            <rect width="28.3636" height="35.4545" fill="white" transform="translate(0 0.545898)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <b-tooltip
      ref="tooltip"
      target="copied-tooltip"
      noninteractive
      disabled
    >
      {{ $t('Copied to clipboard') }}
    </b-tooltip>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Clipboard",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    title: {
      type: String,
      default: "Empty paragraph"
    },
    variables: Object,
    colorSchema: {
      type: String,
      default: "#888888"
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    value () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          const value = this.$store.state[variable];
          result = value;
        }
      }
      return result;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      // copyText.innerText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.innerText);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.ref-wrapper {
  border-radius: 4px;
  border: 2px solid var(--color);
  background: #F0F4FD;
  height: 60px;
  padding: 5px 20px 5px 20px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    background: transparent;
  }
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
    }
  }
}


.title {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: #F0F4FD;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

#copied-tooltip {
  font-weight: 700;
  border: none;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.clipboard {
  cursor: pointer;
  
  @media (max-width: 767px) {
    max-width: 15px;
  }
}

</style>
