<template>
  <div class="product-info">
    <div class="col-lg-5">
      <div class="text">
        Haben Sie nur ein Aktionsprodukt auf Ihrem Kaufbeleg? Dann laden Sie bitte ein Foto der Rückseite des entsprechenden Produktes hoch.
      </div>
      <img class="img-fluid" :src="require(`@/assets/images/png/product-1.png`)" alt="Example image" />
    </div>
    <div class="col-lg-7">
      <div class="text">
        Haben Sie mehrere Aktionsprodukte in einem Kaufvorgang erworben? Dann machen Sie bitte keine getrennten Aufnahmen von den Produkten. Die EAN-Codes aller Ariel-Produkte müssen auf dem Foto gut erkennbar sein.
      </div>
      <img class="img-fluid" :src="require(`@/assets/images/png/product-2.png`)" alt="Example image" />
    </div>
  </div>
</template>
<script>
export default {
  name: "product-information",
  props: {
    texts: {
      type: Object,
      default: () => {},
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    color: {
      type: String,
      default: "#808080"
    },
    image: {
      required: false,
      type: String,
      default: "receipt"
    }
  }
};
</script>
<style lang="scss" scoped>
.product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  margin-left: 20px;

  >div:first-child {
    padding-right: 20px;
    border-right: 2px solid #51D24C66;
  }

  .text {
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    object-fit: contain;
    max-height: 320px;
  }
}
</style>
