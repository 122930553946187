<template>
  <img
    :src="require(`@/${imageSource}`)"
    class="img-fluid"
    :class="classes"
    :alt="alternateText"
    :width="width"
    :height="height"
    :style="styles"
  />
</template>

<script>
export default {
  name: "SquareImage",
  props: {
    imageSource: {
      type: String,
      default: "@/assets/logo.png"
    },
    alternateText: {
      type: String,
      default: "Sample Image"
    },
    width: {
      type: Number,
      default: 100
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: Number,
      default: 100
    },
    styles: {
      type: Object,
      default:()=>{}
    }
  }
};
</script>

<style>
img[data-field-type="square-image"] {
  object-fit: contain;
}
.expiredPageImage{
  max-width: 300px !important;
}
</style>
