<template>
  <div class="container">
    <div class="row">
    </div>
  </div>
</template>
<script>
export default {
  name: "faq"
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--brand-color-1);
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.box-notice {
  background-color: #DFDFDF;
  border: 1px solid black;
  padding: 15px;
  margin: 5px;
  color: #3D3D41;
}
.list {
  list-style-type: decimal;
  word-break: break-word;
  margin: 10px 4px;
  li::marker {
    font-size: 26px;
    font-weight: bold;
    color: var(--brand-color-1);
  }
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--brand-color-1);
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
      display: inline;
    }
}
p {
  margin-bottom: 5px;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--brand-color-1);
  .list-item {
    font-weight: normal;
    white-space: initial;
    font-size: 16px;
    line-height: 24px;
  }
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
    a {
      text-decoration: underline;
    }
  }
}
</style>
