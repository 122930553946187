import { render, staticRenderFns } from "./navbar.vue?vue&type=template&id=3b11c9f4&scoped=true"
import script from "./navbar.vue?vue&type=script&lang=js"
export * from "./navbar.vue?vue&type=script&lang=js"
import style0 from "./navbar.vue?vue&type=style&index=0&id=3b11c9f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b11c9f4",
  null
  
)

export default component.exports