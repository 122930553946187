<template>
  <span></span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "CheckReceiptToken",
  mounted () {
    if (this.frontAndBack) {
      return
    }
    this.check72Hours()
      .then(res => {
        if (!res.response) {
          this.goToPage('expired', this)
        }
      })
    // Need to set frontAndBack to true, next time it will not do  72hrs check due to both front and back reupload
    if (this.$route.fullPath.includes("fe-be-produktfoto-hochladen")) {
      this.$store.commit('setFrontAndBack', true);
    }
  },
  methods: {
    ...mapActions(['check72Hours'])
  },
  computed: {
    ...mapGetters(['frontAndBack'])
  }
}
</script>
