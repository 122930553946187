<template>
  <div
    class="gray_block"
  >
    <div
      class="mainContent"
      tabindex="0"
    >
      <div
        class="inner_gray_block mb-3"
      >
        <div
          class="desc"
          v-html="$t(texts.descText)"
        ></div>
        <div
          class="subDesc"
          v-html="$t(texts.descSubText)"
        ></div>
      </div>
      <div class="row info-wrapper">
        <div class="col-lg-4 col-sm-4 px-0">
          <img
            class="img-fluid receipt-image"
            :src="receiptImage"
            alt="receipt example"
          >
        </div>
        <div class="col-lg-6 col-sm-6">
          <ul class="wraper_punkt mx-2">
            <li
              class="media"
              :key="step.label"
              v-for="step in steps"
            >
              <div class="item">
                {{ $t(step.label) }}
              </div>
              <div class="media-body item_text">
                {{ $t(step.text) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="!hideExamples"
        class="wrapper_check_block"
      >
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              :src="exampleImg(0)"
              alt="Correct"
            >
            <img
              class="checkMark_img checkMark_img_1"
              src="../../assets/images/svg/checkMark.svg"
              alt="Correct"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.correct) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              :src="exampleImg(1)"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_2"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.notReadabe) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              :src="exampleImg(2)"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_3"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.folded) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              :src="exampleImg(3)"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_4"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.cropped) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-information-old",
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    },
    imageSrc: {
      type: String,
      default: ""
    },
    differentExamples: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    },
    receiptImage () {
      if (this.imageSrc) return require(`@/${this.imageSrc}`)
      return require("@/assets/images/png/receipt-old.png")
    },
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    },
    exampleImg (index) {
      if (this.differentExamples) return require(`@/assets/images/png/check_${index+1}_2.png`)
      return require(`@/assets/images/png/check_${index+1}.png`)
    }
  }
};
</script>
<style lang="scss" scoped>
.gray_block {
  background-color: transparent;
  padding: 10px 30px;
  border-radius: 4px;
  @media (max-width: 767px) {
    padding: 5px;
  }
}

.info-wrapper {
  flex-wrap: nowrap;
  > div {
    @media (max-width: 767px) {
      max-width: 50%;
    }
  }
}
.wraper_punkt {
  margin-bottom: 15px;
  li {
    margin-bottom: 15px;
    font-size: 16px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    .item {
      width: 20px;
      height: 20px;
      display: inline-block;
      color: #FFF;
      border-radius: 100px;
      text-transform: uppercase;
      margin-right: 10px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      @media (max-width: 991px) {
        width: 15px;
        height: 15px;
        font-size: 12px;
        line-height: 15px;
      }
    }
    .item_text {
      width: 180px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.16px;
      text-align: left;
      color: #FFF;
      @media(max-width: 1024px){
        font-size: 14px;
      }
      @media (max-width: 668px) {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
}
.wrapper_check_block {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 20px;
  width: 100%;

  .checkMark {
    position: relative;
    .image-wrapper {
      max-width: 90px;
      border: 2px solid rgba(159, 174, 229, 0.3);
      border-radius: 8px;
      padding: 10px;
      background: #FFF;
    }
    &:first-child {
      margin-left: 0px;
    }
    .checkMark_img {
      position: absolute;
      top: 90px;
      left: 100%;
      margin-left: -15px;
      width: 18px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      top: 90px;
      left: 100%;
      margin-left: -15px;
      width: 18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
    .text {
      margin-top: 10px;
      text-align: center;
      word-wrap: break-word;
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
    }
  }
  @media (max-width: 991px) {
    padding-top: 20px;
    flex-wrap: wrap;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.receipt-image {
  width: 100%;
  @media (max-width: 991px) {
    max-width: 150px;
  }
}

.desc {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFF;
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 16px;
    line-height: 20px;
  }
}
.subDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFF;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.drop_down_wrapper {
    padding: 10px !important;
    border-radius: 100px;
    margin-bottom: 20px !important;
}

@media all and (orientation: landscape ) {

    .drop_down {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .drop_down_wrapper {
        padding: 10px !important;
        border-radius: 100px;
        margin-bottom: 20px !important;
    }
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

</style>
