<template>
  <div class="products-container" >
    <product
      v-for="product in products"
      :product="product"
      @itemClick="itemClick(product)"
      :key="product.id"
      :selected="isSelected(product)"
      :class="{ 'active': isSelected(product), 'not-active': selectedItems.length > 0 && !isSelected(product) }"
      :colorSchema="colorSchema != '' ? colorSchema : '#8B8B8B'"
      :cssString="cssString"
      style
    />
    <div class="scroll-btn" v-if="selectedItems.length > 0" @click.prevent="scrollDown">
      <span id="scroll-btn" class="material-icons md-48 d-md-flex d-none">arrow_circle_down</span>
    </div>
  </div>
</template>

<script>
import product from "./product.vue";

export default {
  name: "product-selection",
  components: { product },
  props: {
    products: Array,
    endpoint: String,
    successAction: Object,
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    maxSelectable: {
      required: false,
      type: [String, Number],
      default: -1
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selected:{
      type:[Array,Object],
      default:()=>[]
    },
    fieldId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cssString: "",
      selectedItems: this.selected
    };
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    }
  },
  methods: {
    clear () {
      this.selectedItems = [];
    },
    itemClick (product) {
      let maxSelectableItem = parseInt(this.maxSelectable);
      let isSelectedBefore = this.selectedItems.findIndex(selectedItem => {
        return selectedItem.id == product.id;
      });
      if (product.selectable) {
        if (isSelectedBefore != -1) {
          this.selectedItems.splice(isSelectedBefore, 1);
        } else {
          // This will be use if user selected before (NOT READY YET!)
          // this.selectedItems.forEach(selectedItem => {
          //   if(maxSelectableItem != -1 && selectedItem.selectable == false) {
          //     maxSelectableItem = maxSelectableItem + 1
          //   }
          // });
          if (
            maxSelectableItem != -1 &&
            this.selectedItems.length >= maxSelectableItem
          ) {
            return this.showModal(this.texts.maxSelectable);
          }
          this.selectedItems.push(product);
          this.$emit("component-updated", {
            selected: this.selectedItems
          });
        }
      }
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!this.selectedItems.length
      });
      // this.$emit("input", this.selectedItems);
    },
    isSelected (product) {
      const isSelected = this.selectedItems.find(item => {
        return item.id == product.id;
      });
      return !!isSelected;
    },
    sendProducts () {
      if (!this.selectedItems.length) {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
          message: `<div class='pb-5'>${this.texts.required}</div>`
        });
        return;
      }
      const endpoint = this.endpoint;
      const formData = {
        selectedProducts: this.selectedItems
      };
      this.$store.dispatch("sendProducts", { formData, endpoint }).then(r => {
        if (r.response) {
          // console.log(this.successAction, this);
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
      // console.log("inside send products", this.selectedItems);
    },
    scrollDown () {
      window.scrollTo({
        top: document.getElementsByClassName('products-container')[0].clientHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted () {
    /*
       add get products call to api and set the products to selectable or not.
    */
    this.$eventHub.$on("sendProducts-event", this.sendProducts);
  },
  beforeDestroy () {
    this.clear();
    this.$eventHub.$off("sendProducts-event", this.sendProducts);
  }
};
</script>

<style lang="scss" scoped>
.products-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:wrap;
  column-gap: 20px;
  row-gap: 30px;
  @media(max-width: 450px){
    flex-direction: column;
  }
  div {
    flex: 1 1 30%;
    box-sizing: border-box;
    align-self: stretch;
  }
  .not-active {
    opacity: .5;
  }
}

.scroll-btn {
  @media (min-width: 767px) {
    display: none;
  }
  transition-delay: 1s;
  span {
    cursor: pointer;
  }
  position: fixed;
  bottom: 35vh;
  left: 80vw;
  text-align: right;
}
</style>
