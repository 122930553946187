<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "IbanReupload",
  mounted () {
    this.isUpdateIban().then(res => {
        // If Customer Token Not Found
        if (!res.response) {
          this.onUpdateIbanError()
          return
        }
      })
      .catch(err => {
        this.onUpdateIbanError()
      })
  },
  methods: {
    ...mapActions(['isUpdateIban']),
    onUpdateIbanError () {
      this.goToPage('expired', this)
    }
  }
};
</script>
