<template>
  <div
    class="gray_block"
    :style="[cssVars]"
  >
    <div class="wrapper_check_block clearfix">
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_1.png"
          alt=""
        >
        <img
          class="checkMark_img checkMark_img_1"
          src="../../assets/images/svg/checkMark.svg"
          alt=""
        >
        <!-- <div class="text">{{ this.texts.'correct' }}</div> -->
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_3.png"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_3"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <!-- <div class="text">{{ this.texts.'not readable' }}</div> -->
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_4.png"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_4"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <!-- <div class="text">{{ this.texts.'folded' }}</div> -->
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_2.png"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_2"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <!-- <div class="text">{{ this.texts.'cropped' }}</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptExamples",
  props: {
    colorSchema: {
      type: Object,
      default: () => {
        return {
          correct: "green",
          incorrect: "orange"
        }
      }
    },
    texts: {
      type: Object,
      default: () => {
        return {
          correct: "Correct",
          notReadable: "Not readabled",
          folded: "Folded",
          cropped: "Cropped"
        }
      }
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  },
  computed: {
    cssVars () {
      return {
        '--correct-color': this.colorSchema.correct,
        '--incorrect-color': this.colorSchema.incorrect
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    padding: 30px;
    border-radius: 4px;

    @media (max-width: 991px) {
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
    .inner_gray_block {
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .wrapper_check_block {
    display: flex;
    justify-content: space-around;
    padding: 1vw;
    @media(max-width: 1024px){
      justify-content: center;
    }
    .checkMark {
      position: relative;
      float: left;
      margin: 15px;
      background-color: #EDEDED;
      height: 140px;
      padding: 1vw;
      border-radius: 8px;
      background: var(--incorrect-color);

      .text {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 100%;
      }

      .img-fluid {
        max-height: 100%;
      }
      .checkMark_img {
        position: absolute;
        top: -10%;
        left: 100%;
        max-width: 30px;
        margin-left: -20px;
        @media (max-width: 767px) {
          margin-left: -15px;
        }
      }
      .cross_red_img {
        position: absolute;
        top: -10%;
        left: 100%;
        margin-left: -20px;
        max-width: 30px;
        @media (max-width: 767px) {
          margin-left: -12px;
        }
      }
    }
    :first-child {
      background: var(--correct-color);
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-top: 0;
    }
  }

</style>
