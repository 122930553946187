<template>
  <div>
    <div
      v-if="list.length !== 0"
      class="barcode-scanner__products-list"
      :style="styles"
    >
      <div
        class="barcode-scanner__products-list_item d-flex"
        v-for="item in list"
        :key="item.id"
      >
        <div
          class="row"
          style="width: 100%"
        >
          <div class="col-lg-10 my-2 col-10 d-flex">
            <img
              src="@/assets/images/svg/pglogo.svg"
              alt=""
              width="60px"
              class="mr-2"
            >
            <div class="item-content d-flex">
              <div class="name">
                {{ item.name }}
              </div>
              <div class="ean">
                EAN: {{ item.ean }}
              </div>
            </div>
          </div>
          <div class="col-lg-2 my-2 p-0 col-2 count-wrapper">
            <div class="count">
              {{ item.count }}
            </div>
            <b-input-group>
              <template #append>
                <b-button
                  size="sm"
                  @click="deleteProduct(item.ean)"
                >
                  <img width="20px" src="@/assets/icons/delete.svg" alt="Delete">
                </b-button>
              </template>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"

export default {
  name: 'ProductsList',
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showReturnBackButton: {
      type: Boolean,
      default: false
    },
    maxProductCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts
    })
  },
  methods: {
    ...mapMutations(["editQuantityProduct", "deleteProduct"]),
    editProduct (product, action) {
      let data = { product, action };
      this.editQuantityProduct(data);
      if (!product.count) {
        this.deleteProduct(product.id);
      }
    },
    goToSelectProducts () {
      this.goToPage({
        pageName: 'productSelection',
        shouldBack: true
      }, this)
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__products-list {
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #B4A4E5;
  box-sizing: border-box;
  border-radius: 16px;

  @media (max-width: 767px) {
    padding: 0;
  }
  &_item {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    div {
      align-items: center;
    }
    .item-content {
      flex-direction: column;
      padding-left: 10px;
      align-items: flex-start;

      letter-spacing: 0px;
      color: #000000;


      .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
      }

      .ean {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.count-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  .count {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    padding: 5px 10px;
    color: #FFF;
    border-radius: 3px;
    background: #010B48;
  }

  .btn {
    background: transparent;
    border: none;
  }

  .input-group {
    width: fit-content;
  }
}

</style>
