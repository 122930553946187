<template>
  <div class="m-0 p-0">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name.toLowerCase()"
    >
      <div class="form-group">
        <div class="input-group">
          <label
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="input-label"
          >
            {{ label }}
          </label>
          <datepicker
            :value="value"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            v-bind="datepickerParams"
            @selected="updateValue"
            @opened="floatLabel = true"
            @closed="floatLabel = false"
            required
            :typeable="false"
            wrapper-class="w-100"
            :input-class="['form-control', {'is-invalid': errors.length}]"
          >
          </datepicker>
          <span
            v-if="!labelOnTop && !noLabel"
            :class="{floatLabel: floatLabel || value}"
            class="floating-label"
          >{{ label }}</span>
        </div>
        <div
          class="invalid-feedback"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="form-group">
        <div class="input-group">
          <label
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="input-label"
          >
            {{ label }}
          </label>
          <datepicker
            :value="value"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            v-bind="datepickerParams"
            @selected="updateValue"
            @opened="floatLabel = true"
            @closed="floatLabel = false"
            required
            :typeable="false"
            wrapper-class="w-100"
            :input-class="['form-control']"
          >
          </datepicker>
          <span
            v-if="!labelOnTop && !noLabel"
            :class="{floatLabel: floatLabel || value}"
            class="floating-label"
          >{{ label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "DateInput",
  components: {
    Datepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: Date,
      default: () => {}
    },
    validation: {
      type: String,
      required: false
    },
    params: {
      type: Object,
      required: false
    },
    styles:{
      type: Object,
      default: ()=>{}
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      defaut: ""
    }
  },
  computed: {
    datepickerParams () {
      const params = { ...this.params }
      if (params.disabledDates) {
        if (params.disabledDates.to) {
          params.disabledDates.to = this.convertToDate(params.disabledDates.to)
        }
        if (params.disabledDates.from) {
          params.disabledDates.from = this.convertToDate(params.disabledDates.from)
          params.openDate = params.disabledDates.from
        }
      }
      return params
    }
  },
  data () {
    return {
      floatLabel: false
    }
  },
  methods: {
    updateValue (value) {
      this.$emit("component-updated", {
        value
      });
    },
    convertToDate (date) {
      if (date === "today") {
        return new Date()
      } else if (date === "18years") {
        const date = new Date()
        date.setFullYear(date.getFullYear() - 18)
        return date
      } else {
        return new Date(date)
      }
    }
  }
};
</script>
