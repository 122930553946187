<template>
  <span></span>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "query-string-to-state",
  props: {
    variables: Array
  },
  mounted () {
    const isJwt = this.parseJWT(this.$route.query.token || this.$route.query.customerToken)
    if (isJwt) {
      const { payload } = isJwt
      this.variables.forEach(variable=>{
        this.setField({ field: variable.state, value: payload[variable.state] })
      })
    } else {
      this.variables.forEach(variable=>{
        if (this.$route.query[variable.query]) this.setField({ field: variable.state, value: this.$route.query[variable.query] })
      })
    }
  },
  methods: {
    ...mapMutations(['setField']),
    parseJWT(token) {
      const parts = token.split('.');
      if (parts.length === 3) {
        const base64UrlPattern = /^[A-Za-z0-9_-]+$/;
        // Check if each part is base64Url encoded
        if (base64UrlPattern.test(parts[0]) && base64UrlPattern.test(parts[1]) && base64UrlPattern.test(parts[2])) {
          try {
            // Decode header and payload
            const header = JSON.parse(atob(parts[0].replace(/_/g, '/').replace(/-/g, '+')));
            const payload = JSON.parse(atob(parts[1].replace(/_/g, '/').replace(/-/g, '+')));
            return { header, payload }; // Return decoded JWT parts
          } catch (e) {
            console.error('Error decoding JWT:', e);
            return false; // Return false if there is an error decoding
          }
        }
      }
      return false; // Not a JWT
    }
  }
};
</script>
